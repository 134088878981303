import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Article from '../components/Article/Article'



const articleTemplate = (props) => {

  //console.log(props);

  const { drupal: article } = props.data;

  return (
    <Layout>
        <Article 
        title={article.nodeById.title} 
        heading={article.nodeById.fieldHeadingBody} 
        body_yo={article.nodeById.body}
        nid={article.nodeById.nid}
        langcode={article.nodeById.path.langcode}
        translations={article.nodeById.entityTranslations}
        files={article.nodeById.fieldFiles}
        images={article.nodeById.fieldImage}
        video={article.nodeById.fieldVideo} 
        pageurl={article.nodeById.entityUrl.path} 
        termpath={article.nodeById.fieldGrowthObjectiveTerm} />
    </Layout>
  )
};

export default articleTemplate;

// The $drupal_id variable here is obtained from the "context" object passed into
// the createPage() API in gatsby-node.js.
//
// Also note the use of field name aliasing in the query. This is done to
// help normalize the shape of the article data.
export const query = graphql`
  query ArticleTemplate($drupal_id: String!, $language: Drupal_LanguageId) {
    drupal {
      nodeById(id: $drupal_id, language: $language) {
        ... on Drupal_NodeGrowthObjectiveData {
          nid
          entityId
          entityUuid
          title
          fieldHeadingBody {
            value
            processed
          }
          body {
            value
            processed
          }
          path {
            langcode
            alias
          }
          fieldImage
          fieldVideo
          fieldFiles
          fieldGrowthObjectiveTerm {
            entity {
              name
              tid
              parent {
                entity {
                  name
                }
              }
            }
          }
          entityLanguage {
            argument
          }
          entityTranslations {
            ... on Drupal_NodeGrowthObjectiveData {
              nid
              title
              path {
                alias
                langcode
              }
              entityLanguage {
                argument
              }
            }
          }
        }
        entityUrl {
          path
        }
      }
    }
  }
`;
