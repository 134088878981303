import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Header from "../../components/header"
import SEO from "../../components/seo"
import FilesBlock from "../../components/FilesBlock/FilesBlock"
import Buttons from "../../components/clipboard"
require('es6-promise').polyfill();
require('isomorphic-fetch');

const Article = (props) => {
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    fetch('/.netlify/functions/cookie', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (response.status !== 200) {
        window.location.replace('https://rsmapplications.rsmlink.global/_layouts/15/RSM.ConnectPlus/Login.aspx?ReturnUrl=https://growthplatform.rsmlink.global/');
      } else {
        setAuth(true);
        response.json().then(data => {
          let responseMeasure = data.rsmMember;
          let jsonMeasure = JSON.parse(responseMeasure)
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'memberFirm': jsonMeasure.MemberFirmName,
          });
        });
      }
    })
    .catch(err => console.log(err));
  }, []);

  let pageurl = "https://growthplatform.rsmlink.global"+props.pageurl;

  let nodeEmailSubject = '';
  if(props.termpath !== null){
    if(props.termpath.entity.parent[0].entity !== null){
      nodeEmailSubject += props.termpath.entity.parent[0].entity.name+', ';
    }
    nodeEmailSubject += props.termpath.entity.name+', '+props.title;
  } else {
    nodeEmailSubject += props.title;
  }

  let videoIframe;
  if(props.video){
    var videoUrl = "https://player.vimeo.com/video/"+props.video
    videoIframe = <iframe title="Vimeo" src={videoUrl} width="100%" height="400" allow="autoplay; fullscreen"></iframe>
  }

  if (!auth) {
    return <div />;
  }

  return (
    <>
      <SEO title={props.title} />
      <Header langsToCall={props.translations} currentLang={props.langcode} />
      <div className="site-wrapper node-wrapper">
  	    <div className="node-page" nid={props.nid} langcode={props.langCode}>
          <div className="three-line-block">
            <span className="line-block-one"></span>
            <span className="line-block-two"></span>
            <span className="line-block-three"></span>
          </div>
  	      <h2>{props.title}</h2>

          {
            (() => {
              switch(props.heading) {
                case null: return null;
                default:  return <div className="heading-field" dangerouslySetInnerHTML={{ __html: props.heading.processed }}></div>;
              }
            }) ()
          } 

          {
            (() => {
              switch(props.body_yo) {
                case null: return null;
                default:  return <div className="body-field" dangerouslySetInnerHTML={{ __html: props.body_yo.processed }}></div>;
              }
            }) ()
          } 

          {videoIframe}

          {(() => {
            switch(props.images) {
              case null: return null;
              default:  return <img className="node-image" src={"https://growthplatformassets.rsmlink.global/files/Files_and_images/" + props.images} />;
            }
          }) ()}

          {(() => {
            switch(props.files.length > 0) {
              case true: return <FilesBlock filesAttached={props.files} />;
              default:  return ``;
            }
          }) ()}

          <Buttons pagetitle={props.title} pageurl={pageurl} langcode={props.langcode} />

          <div className="help-container">
            <a className="help-link" href={"mailto:?subject="+nodeEmailSubject} target="_self" rel="noopener" aria-label="Share by E-Mail">
              Can’t find the information you need? Email Us!
            </a>
          </div>

        </div>
      </div>
    </>
  );

};

Article.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object
}

export default Article;