import React from 'react';

const FilesBlock = (props) => {

	let filesList = [];

	props.filesAttached.forEach(function(file, i) {     
    // Loop through all files inside node
    // Get the filename and file type. Example word.pdf
		var filename_w_ext = file.substring(file.lastIndexOf('/') + 1).replace(/_/g, ' ');

    // File name. Example this_is_a_file from this_is_a_file.pdf
    var filename = filename_w_ext.substring(0, filename_w_ext.lastIndexOf('.'));

    // File type. Example .pdf
		var fileext = filename_w_ext.substring(filename_w_ext.lastIndexOf('.') + 1);

		filesList.push({
      'file_extension': fileext,
      'file_name': filename,
      'file_link': file,
    });
  });

  return (
    <>
    	<div className="filesBlock">
	      <ul className="file-list">
	      {
	        filesList.map( (file, index) => (
	        <li key={index}>
		        <div className="file-list-container">
		          <a target="_blank" rel="noopener noreferrer" href={"https://growthplatformassets.rsmlink.global/files/Files_and_images/" + file.file_link}
		          >
		          	<div className="file-icon file-icon-lg" data-type={file.file_extension}></div>{file.file_name}
		          </a>
	          </div>
	        </li>
	        ))
	      }
	      </ul>
      </div>
    </>
  );
};

export default FilesBlock;